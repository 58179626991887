import Vue from 'vue'
import VueRouter from 'vue-router'
import home from '../views/Home.vue'
import ballast from '../views/Ballast.vue'
import timelineOLD from '../views/CharityTimeLine.vue'
import charity from '../views/Charity.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: home
  },
  {
    path: '/ballast',
    name: 'ballast',
    component: ballast
  },
  {
    path: '/charity',
    name: 'charity',
    component: charity
  },
  {
    path: '/sa8000',
    name: 'SA8000',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/sa8000.vue')
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },

  
  {
    path: '/c',
    name: 'timelineOLD',
    component: timelineOLD
  }





]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
