const clients = 
[{
    logoImage: "icon_charity.png",
    clientName: "2023 Декември - Коледен университет на добротата",
    clientUrl: "#"
  },
  {
    logoImage: "icon_charity.png",
    clientName: "2023 - Сподели Добро за ДОМ ЗА ДЕЦА С УМСТВЕНА ИЗОСТАНАЛОСТ „СВЕТИ ПАНТЕЛЕЙМОН“, с. Видраре",
    clientUrl: "#"
  },
  {
    logoImage: "icon_charity.png",
    clientName: "2023 Май - Университет на добротата Изложба „Пътят на светлината“",
    clientUrl: "/page15"
  },
  {
    logoImage: "icon_charity.png",
    clientName: "2023 - Сподели Добро – ДСХ „Сем. Калинкови“",
    clientUrl: "/page14"
  },
  { logoImage: "icon_charity.png", 
    clientName: "2023 -	Сподели Добро – Лазаровден", 
    clientUrl: "#" },
  {
    logoImage: "icon_charity.png",
    clientName:"2022 Декември - Коледен университет на добротата",
    clientUrl: "/page12"
  },
  {
    logoImage: "icon_charity.png",
    clientName:"2022 - Добротата е заразна - Изложба „Втори шанс за красотата“",
    clientUrl: "/page11"
  },
  {
    logoImage: "icon_charity.png",
    clientName: "2022 - Сподели Добро – ДСХ „Сем. Калинкови“",
    clientUrl: "#"
  },
  {
    logoImage: "icon_charity.png",
    clientName: "2022 - За баба Марта с любов",
    clientUrl: "#"
  },
  {
    logoImage: "icon_charity.png",
    clientName: "2021 - За баба Марта с любов",
    clientUrl: "#"
  },
  {
    logoImage: "icon_charity.png",
    clientName: "2020 - За баба Марта с любов",
    clientUrl: "#"
  },
  {
    logoImage: "icon_charity.png",
    clientName: "2019 юни - Университет на добротата",
    clientUrl: "#"
  },
  {
    logoImage: "icon_charity.png",
    clientName: "2019 - За баба Марта с любов",
    clientUrl: "#"
  },
  {
    logoImage: "icon_charity.png",
    clientName: "2018 - За баба Марта с любов",
    clientUrl: "#"
  },
  {
    logoImage: "icon_charity.png",
    clientName: "2017 - За баба Марта с любов",
    clientUrl: "#"
  },
  {
    logoImage: "icon_charity.png",
    clientName: "2016 - За баба Марта с любов",
    clientUrl: "#"
  },
  {
    logoImage: "icon_charity.png",
    clientName: "2015 - За баба Марта с любов",
    clientUrl: "#"
  }];
export default clients;  