import Vue from 'vue'
import App from './App.vue'
import "tailwindcss/tailwind.css"
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'

Vue.component('vue-pdf-embed', VuePdfEmbed)
/* import the fontawesome core */
//import { library } from './fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
//import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
//import { faUserSecret } from '@fortawesome/free-solid-svg-icons'

/* add icons to the library */
//library.add(faUserSecret)

Vue.config.productionTip = false

Vue.prototype.$scrollToTop = () => window.scrollTo(0,0)


new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
