<template>
<div class="m-12">
  <v-timeline dense >
   
    <v-timeline-item 
      v-for="(item, i) in items"
      :key="i"
      color="yellow"      
    >
    <template v-slot:icon>
       <span
          class="pr-2 headline font-weight-bold text-yellow-600"
          v-text="item.year"
      ></span>
        <v-icon large :color="item.color" class="mr-16">mdi-star</v-icon>
      </template>

      <template v-slot:opposite>
        <span
          class="headline font-weight-bold text-yellow-600"
          v-text="item.year"
        ></span>
      </template>

      <v-carousel
        cycle        
        hide-delimiter-background
        show-arrows-on-hover hide-delimiters
      >
        <v-carousel-item
          v-for="(image,i) in item.srcImages"
          :key="i"
          :src="image"
        >  
          <v-sheet
            color="#ffffff70"
            height="110%"
            width="20%"
            tile
          >
            <v-row
              class="mx-2 fill-height"
              align="center"
            >
              <div class="text-4xl text-yellow-600 text-center">
                <span
                  class="headline font-weight-bold text-yellow-600"
                  v-text="item.imagesText"
                ></span>
              </div>
            </v-row>
          </v-sheet>
        </v-carousel-item>
      </v-carousel>      
    </v-timeline-item>   
        

  </v-timeline>
</div>
</template>
<script>
  export default {
    data: () => ({ 
      items: [
      
      {
            year: '2023',
            color: '#ca8a04',
            icon: 'mdi-star',
            imagesText: `Вече за втора поредна година Фондация ,,Здравно бъдеще“ реализира благотворителна инициатива в Дом за стари хора ,,Сем. д-р Калинкови“, споделяйки Великден с възрастните хора от дома.
`,
             srcImages:['https://health-future.eu/img/010423/1.jpg',
                       'https://health-future.eu/img/010423/2.jpg',
                       'https://health-future.eu/img/010423/3.jpg',
                       'https://health-future.eu/img/010423/4.jpg',
                       'https://health-future.eu/img/010423/5.jpg',  
                       'https://health-future.eu/img/010423/6.jpg', 
                       'https://health-future.eu/img/010423/7.jpg',  
                       'https://health-future.eu/img/010423/8.jpg',                       
                      ]
          },

      {
            year: '2023',
            color: '#ca8a04',
            icon: 'mdi-star',
            imagesText: 'Проектът "Сподели добро" на Фондация "Здравно Бъдеще" взе участие в иницитивата на "Лайънс клуб София Витоша" - "Великденска работилница',
            srcImages:['https://health-future.eu/img/080423/1.jpg',
                       'https://health-future.eu/img/080423/2.jpg',
                       'https://health-future.eu/img/080423/3.jpg',
                       'https://health-future.eu/img/080423/4.jpg',
                       'https://health-future.eu/img/080423/5.jpg',  
                       'https://health-future.eu/img/080423/6.jpg',                        
                      ]
          },
          
          {
            year: '2023',
            color: '#ca8a04',
            icon: 'mdi-star',
            imagesText: 'Празник на добротата, изкуството и българските традиции! Първата самостоятелна  изложба в гр. София на човекът с голямо сърце и богата четка-художникът Стефан Четроков! Изложба с кауза ,,Пътят на светлината” в подкрепа на каузите на Фондация ,,Здравно бъдеще”! Когато сме добри и съпричастни, огледалото на Вселената ще ни се усмихва винаги!Обединени можем повече!',
            srcImages:['https://health-future.eu/img/110523/1.jpg',
                       'https://health-future.eu/img/110523/2.jpg',
                       'https://health-future.eu/img/110523/3.jpg',
                       'https://health-future.eu/img/110523/4.jpg',
                       'https://health-future.eu/img/110523/5.jpg',                        
                      ]
          },
          
          {
            year: '2022',
            color: '#ca8a04',
            icon: 'mdi-star',
            imagesText: 'Около 45 семейства имат нужда от детски батут за децата им с "65 рози"',
            srcImages:['https://health-future.eu/img/191222/1.jpg',
                       'https://health-future.eu/img/191222/2.jpg',
                       'https://health-future.eu/img/191222/3.jpg',
                       'https://health-future.eu/img/191222/4.jpg',
                       'https://health-future.eu/img/191222/5.jpg',  
                       'https://health-future.eu/img/191222/6.jpg',                        
                      ]
          },
          
          {
            year: '2021',
            color: '#ca8a04',
            icon: 'mdi-star',
            imagesText: 'Фондация здравно бъдеще',
            srcImages:['https://health-future.eu/img/charity/MGA_7787.jpg',
                       'https://health-future.eu/img/charity/MGA_7746.jpg',
                       'https://health-future.eu/img/charity/MGA_7774.jpg',
                       'https://health-future.eu/img/charity/MGA_7754.jpg',
                       'https://health-future.eu/img/charity/MGA_8022.jpg',                        
                      ]
          },
          {
            year: '2020',
            color: '#ca8a04',
            icon: 'mdi-star',
            imagesText: 'Инициатива 2020',
            srcImages:[ 'https://health-future.eu/img/charity/MGA_7738.jpg',
                       'https://health-future.eu/img/charity/MGA_8247.jpg',
                       'https://health-future.eu/img/charity/MGA_8302.jpg',
                       'https://health-future.eu/img/charity/MGA_8400.jpg',
                      ]
          },
          {
            year: '2019',
            color: '#ca8a04',
            icon: 'mdi-star',
            imagesText: 'Инициатива 2019',
            srcImages:[ 'https://health-future.eu/img/charity/MGA_7746.jpg',
                       'https://health-future.eu/img/charity/MGA_8247.jpg',
                       'https://health-future.eu/img/charity/MGA_8302.jpg',
                       'https://health-future.eu/img/charity/MGA_8400.jpg',
                      ]
          },
          {
            year: '2018',
            color: '#ca8a04',
            icon: 'mdi-star',
            imagesText: 'Инициатива 2018',
            srcImages:[ 'https://health-future.eu/img/charity/MGA_7743.jpg',
                       'https://health-future.eu/img/charity/MGA_8247.jpg',
                       'https://health-future.eu/img/charity/MGA_8302.jpg',
                       'https://health-future.eu/img/charity/MGA_8400.jpg',
                      ]
          },
          {
            year: '2017',
            color: '#ca8a04',
            icon: 'mdi-star',
            imagesText: 'Инициатива 2017',
            srcImages:[ 'https://health-future.eu/img/charity/MGA_8310.jpg',
                       'https://health-future.eu/img/charity/MGA_8247.jpg',
                       'https://health-future.eu/img/charity/MGA_8302.jpg',
                       'https://health-future.eu/img/charity/MGA_8400.jpg',
                      ]
          },
          {
            year: '2016',
            color: '#ca8a04',
            icon: 'mdi-star',
            imagesText: 'Инициатива 2016',
            srcImages:[ 
                       'https://health-future.eu/img/charity/MGA_8302.jpg',
                        'https://health-future.eu/img/charity/MGA_8310.jpg',
                       'https://health-future.eu/img/charity/MGA_8247.jpg',
                       'https://health-future.eu/img/charity/MGA_8400.jpg',
                      ]
          },
          {
            year: '2015',
            color: '#ca8a04',
            icon: 'mdi-star',
            imagesText: 'Инициатива 2015',
            srcImages:[ 
                       'https://health-future.eu/img/charity/MGA_8400.jpg',
                        'https://health-future.eu/img/charity/MGA_8310.jpg',
                       'https://health-future.eu/img/charity/MGA_8247.jpg',
                       'https://health-future.eu/img/charity/MGA_8302.jpg',
                      ]
          },
          
           
        ],
    }),

    methods: {
      
    },
  }
</script>

<style lang="scss" scoped> 
 
</style>