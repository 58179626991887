<template>
  <div class="max-w-screen-2xl w-screen card">


    <div class="app-header">
      <template v-if="isLoading"> Loading... </template>

      <template v-else>
        <span v-if="showAllPages"> {{ pageCount }} page(s) </span>

        <span v-else>
          <button :disabled="page <= 1" @click="page--">❮</button>
          {{ page }} / {{ pageCount }}
          <button :disabled="page >= pageCount" @click="page++">❯</button>
        </span>

        <label class="right">
          <input v-model="showAllPages" type="checkbox" />
          Show all pages
        </label>
      </template>
    </div>

    <div class="app-content">
      <vue-pdf-embed
          ref="pdfRef"
          :source="pdfSource"
          :page="page"
          @password-requested="handlePasswordRequest"
          @rendered="handleDocumentRender"
      />
    </div>

  </div>

<!--    <div class="w-screen h-screen ">-->
<!--&lt;!&ndash;      <div class="mx-aut-o card">&ndash;&gt;-->
<!--        &lt;!&ndash; <img class="" src="@/assets/page15/1.jpg" /> &ndash;&gt;-->
<!--&lt;!&ndash;          <h2 class="mt-8 name text-xl md:text-3xl">&ndash;&gt;-->
<!--&lt;!&ndash;            Ballast&ndash;&gt;-->
<!--&lt;!&ndash;          </h2>&ndash;&gt;-->
<!--       -->
<!--        &lt;!&ndash; HEADER &ndash;&gt;-->
<!--        &lt;!&ndash; TEXT BEGIN&ndash;&gt;-->
<!--        <transition name="component-fade" mode="out-in">-->
<!--        <div class="mx-auto">-->
<!--          <span class="text-2xl font-extrabold">Ballast</span><br/>-->
<!--          <span v-if="showAllPages"> {{ pageCount }} page(s) </span>-->

<!--          <span v-else>-->
<!--        <button :disabled="page <= 1" @click="page&#45;&#45;">❮</button>-->

<!--        {{ page }} / {{ pageCount }}-->

<!--        <button :disabled="page >= pageCount" @click="page++">❯</button>-->
<!--      </span>-->

<!--          <label class="right">-->
<!--            <input v-model="showAllPages" type="checkbox" />-->

<!--            Show all pages-->
<!--          </label>-->
<!--        </div>-->
<!--        </transition>-->
<!--  -->
<!--&lt;!&ndash;      </div>&ndash;&gt;-->
<!--    </div>-->
  </template>
  
  <script>
  export default {

    data() {
      return {
        isLoading: true,
        page: null,
        pageCount: 1,
        showAllPages: true,

        images: [],
        pdfSource: 'https://oceanmedical.eu/img/pdf/BALLAST.PDF',
      };
    },
  
    mounted() {    
      //this.importAll(require.context('@/assets/page5/', true, /\.jpg$/));
    },
    watch: {
      showAllPages() {
        this.page = this.showAllPages ? null : 1
      }
    },
    methods: {
      handleDocumentRender(args) {
        console.log(args)
        this.isLoading = false
        this.pageCount = this.$refs.pdfRef.pageCount
      },
      handlePasswordRequest(callback, retry) {
        callback(prompt(retry ? 'Enter password again' : 'Enter password'))
      }
      // importAll(r) {
      //   r.keys().forEach(key => (this.images.push({id:r(key), pathLong: r(key), pathShort: key })));
      // },
    },
  };
  </script>

  <style lang="scss" scoped>
  body {
    margin: 0;
    padding: 0;
    background-color: #ccc;
  }

  .vue-pdf-embed > div {
    margin-bottom: 8px;
    box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.1);
  }

  .app-header {
    padding: 16px;
    box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.1);
    background-color: #555;
    color: #ddd;
  }

  .app-content {
    padding: 24px 16px;
  }

  .right {
    float: right;
  }


  .stack {
      display: flex;
      justify-content: space-evenly;
      flex-wrap: wrap;
      align-items: stretch;
      //flex-direction: column;
    }

    .cardImage {
      width: 20%;
      height: 18rem;
    }

    .cardText {
      width: 80%;
    }

    /** this is sample */
    .card {
      background-color: #fff;
      min-width: 360px;
      max-width: 960px;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      border-radius: 2rem;
      box-shadow: 0px 1rem 1.5rem rgba(0, 0, 0, 0.5);
    }
    .card .banner {
      // background-image: url(".././assets/activity/4.jpg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      height: 11rem;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      box-shadow: 0px 0.1rem 1.2rem rgba(0, 0, 0, 0.1);
      //box-sizing: border-box;
    }
    .card .banner img {
      background-color: rgba(255, 255, 255, 0.288);
      width: 6rem;
      height: 6rem;
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.3);
      border-radius: 50%;
      transform: translateY(90%);
      transition: transform 200ms cubic-bezier(0.18, 0.89, 0.32, 1.28);
      opacity: 0.8;
    }
    .card .banner img:hover {
      transform: translateY(90%) scale(1.2);
      border-radius: 0%;
      opacity: 1;
    }
    .card .menu {
      width: 100%;
      height: 5.5rem;
      padding: 1rem;
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
      position: relative;
      box-sizing: border-box;
    }

    .card h2.name {
      text-align: center;
      padding: 0 2rem 0.5rem;
      margin: 0;
      font-weight: bolder;
    }
    .card .title {
      font-size: 0.1rem;
    }
    .card .actions {
      padding: 0 2rem 1.2rem;
      display: flex;
      flex-direction: column;
      order: 99;
    }
    .card .actions .follow-info {
      padding: 0 0 1rem;
      display: flex;
    }
    .card .actions .follow-info h2 {
      text-align: center;
      width: 50%;
      margin: 0;
      box-sizing: border-box;
    }
    .card .actions .follow-info h2 a {
      text-decoration: none;
      padding: 0.8rem;
      display: flex;
      flex-direction: column;
      border-radius: 0.8rem;
      transition: background-color 100ms ease-in-out;
    }
    .card .actions .follow-info h2 a span {
      color: #1c9eff;
      font-weight: bold;
      transform-origin: bottom;
      transform: scaleY(1.3);
      transition: color 100ms ease-in-out;
    }
    .card .actions .follow-info h2 a small {
      color: #afafaf;
      font-size: 0.85rem;
      font-weight: normal;
    }
    .card .actions .follow-info h2 a:hover {
      background-color: #f2f2f2;
    }
    .card .actions .follow-info h2 a:hover span {
      color: #007ad6;
    }
    .card .actions .follow-btn button {
      color: inherit;
      font: inherit;
      font-weight: bold;
      background-color: #ffd01a;
      width: 100%;
      border: none;
      padding: 0.1rem;
      outline: none;
      box-sizing: border-box;
      border-radius: 1.5rem/50%;
      transition: background-color 100ms ease-in-out,
        transform 200ms cubic-bezier(0.18, 0.89, 0.32, 1.28);
    }
    .card .actions .follow-btn button:hover {
      background-color: #efb10a;
      font-size: 24px;
      transform: scale(1.1);
    }
    .card .actions .follow-btn button:active {
      background-color: #e8a200;
      transform: scale(1);
    }
    .card .desc {
      text-align: justify;
      padding: 0 2rem 2.5rem;
      order: 100;
      font-size: 22px;

    }

  .component-fade-enter-active,
  .component-fade-leave-active {
    transition: opacity 0.15s ease;
  }
  .component-fade-enter,
  .component-fade-leave-to {
    opacity: 0;
  }
  </style>