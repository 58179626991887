<template>
  <div>
    <transition name="component-fade" mode="out-in">
      <div class="text-4xl headerMain p-3">
        <h1>Нашите инициативи</h1>
      </div>
    </transition>
    <transition-group name="fade" tag="div" @click.native="$scrollToTop">
      <div v-for="client in clients" :key="client.clientName">
        <div class="media border p-3 newsMain">
          <div class="media-body flex">
            <div>

              <img :src="require('../assets/' + client.logoImage)" class="logoImage" />
            </div>
            
            <router-link :ref="client.clientUrl" tag="button">
              <h4 class="text-2xl headerText">{{ client.clientName.toUpperCase() }}</h4>
            </router-link>
            <a :href="client.clientUrl">
              <h4 class="text-2xl headerText">{{ client.clientName.toUpperCase() }}</h4>
            </a>
          </div>
        </div>
      </div>
    </transition-group>
  </div>
</template>

<script>
import clients from ".././ResourceData/clients.js";

export default {
  data() {
    return {
      clients
    };
  }
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

@media screen and (max-width: 770px) {
  .headerText {
    font-size: 1em;
  }
}
a {
  text-decoration: none;
}

.headerMain {
  height: 80px;
  //background-color: rgb(233, 233, 233);
  border-radius: 1%;
  box-shadow: 0px 10px 10px -10px rgba(156, 135, 92, 0.407);
  //background-image: linear-gradient(#a098a541, #ffffff5d);
  h1 {
    color: rgb(156, 135, 92);
    text-shadow: 4px 4px 18px #bcb71d3a;
    text-align: center;
  }
  &:hover {
    filter: drop-shadow(8px 8px 10px gray);
  }
}
.newsMain {    
  border-radius: 1%;
  text-shadow: 2px 2px 4px #2e0d3d3a;
  background-color: rgb(233, 233, 233);
  box-shadow: 0px 8px 16px 0px rgba(156, 135, 92, 0.507);
  margin: 30px;
  &:hover {
    filter: drop-shadow(8px 8px 10px gray);
    .logoImage {
      filter: drop-shadow(8px 8px 10px rgb(128, 128, 128));
    }
    .headerText {
      color: black;
    }
  }
}

.logoImage {
  width: 70px;
  min-width: 70px;
  min-height: 65;
  height: 65px;
}

.headerText {
  color: gray;
  margin-top: 15px;
  margin-left: 15px;
}
</style>